import type * as Stitches from "@stitches/react"
import Link from "next/link"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { TProduct, routes } from "src/services"
import { styled } from "src/stitches.config"

import { P } from "../typography"
import { SportProductCardContainer, SportProductIcon } from "./fragments"

export type SportProductCardType = Pick<TProduct, "id" | "systemStatus" | "name" | "type" | "subtype"> //prettier-ignore

interface Props
  extends Stitches.VariantProps<typeof SportProductCardContainer> {
  sportProduct: SportProductCardType
}

const SportProductCardContent = styled("div", {
  position: "relative",
  padding: "$20",
  textAlign: "end",

  "@mdDown": {
    padding: "$10",
  },

  variants: {
    systemStatus: {
      inactive: {
        opacity: ".3",
      },
      new: {
        "& > *": {
          color: "$gray",
        },
      },
      active: {},
      deleted: {},
    },
  },
})

const SportProductIconWrapper = styled("div", {
  position: "absolute",
  left: "0",
  top: "50%",
  width: "$60",
  transform: "translate(-27%, -50%)",
  opacity: "0.05",

  "@mdDown": {
    width: "$40",
  },
})

export const SportProductCard = ({ sportProduct }: Props) => {
  const { formatMessage } = useIntl()

  const url = useMemo(() => {
    return routes["sports-products"].get.route.replace(":id", sportProduct.id)
  }, [])

  return (
    <SportProductCardContainer
      systemStatus={sportProduct.systemStatus.name}
      data-testid="sport-product-item"
    >
      {sportProduct.systemStatus.name === "active" && (
        <SportProductIconWrapper>
          <SportProductIcon name={sportProduct.type.name} />
        </SportProductIconWrapper>
      )}
      <SportProductCardContent systemStatus={sportProduct.systemStatus.name}>
        <P size="large" bold noMargin>
          {url ? (
            <Link href={url} data-cy="card-link">
              {sportProduct.name}
            </Link>
          ) : (
            sportProduct.name
          )}
        </P>

        <P noMargin>
          {formatMessage({
            id: `sportsProductType.${sportProduct.type?.name}`,
          })}

          {sportProduct.subtype?.name && `, ${sportProduct.subtype?.name}`}
        </P>
      </SportProductCardContent>
    </SportProductCardContainer>
  )
}
