import { Fragment, forwardRef, useCallback, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import Select, { CommonProps, OptionTypeBase } from "react-select"
import { OVERLAY_ZINDEX } from "src/constants"
import useIntlOption from "src/hooks/useIntlOption"
import { styled } from "src/stitches.config"

import { DropdownArrow } from "../dropdown-arrow"
import { sizes as inputSizes } from "../input"
import { Overlay } from "../overlay"

export interface Option {
  id?: number | string
  value: string
  label: string
}

interface Props {
  options: Option[]
  selectedOption?: Option | string | null
  onChange?: (arg: Option) => void
  instanceId?: string
  error?: string
  placeholder?: string
  noDefault?: boolean
  isDisabled?: boolean
  name?: string
  isClearable?: boolean
}

interface WrappedProps extends Props {
  name: string
}

const CustomIndicatorSeparator = () => {
  return null
}

const CustomDropdownIndicator = ({
  selectProps,
}: CommonProps<OptionTypeBase, false>) => {
  return <DropdownArrow expanded={selectProps.menuIsOpen} />
}

const InputError = styled("div", {
  color: "$red",
  marginLeft: "$10",
})

const StyledSelect = styled(Select, {
  color: "$inputText",

  ".react-select-container": {
    outline: 0,
    cursor: "pointer",
  },
  ".react-select__control": {
    minWidth: 190,
    height: `${inputSizes.regular}px`,
    paddingRight: "1em",
    borderRadius: "4px",
    border: "1px solid $inputBorder",
    "&:hover": {
      border: "1px solid $inputBorder",
      boxShadow: "none",
    },
    "&.react-select__control--menu-is-open, &.react-select__control--is-focused":
      {
        boxShadow: "none",
      },
  },
  ".react-select__menu": {
    margin: "0",
    border: "1px solid $inputBorder",
  },
  ".react-select__option": {
    color: "$inputText",
    "&:hover": {
      backgroundColor: "$inputHover",
    },
    "&.react-select__option--is-selected": {
      color: "$black",
      backgroundColor: "transparent",
    },
  },
  variants: {
    isFocused: {
      true: {
        zIndex: OVERLAY_ZINDEX,
      },
    },
    size: {
      small: {
        ".react-select__control": {
          height: `${inputSizes.small}px`,
        },
      },
      regular: {
        ".react-select__control": {
          height: `${inputSizes.regular}px`,
        },
      },
    },
  },
})

export const DropdownSelect = forwardRef(
  (
    {
      options,
      selectedOption,
      instanceId,
      onChange,
      error,
      placeholder,
      noDefault,
      isDisabled,
      name,
      isClearable = false,
    }: Props,
    ref,
  ) => {
    const intlOption = useIntlOption()
    const { formatMessage } = useIntl()
    const [isFocused, setIsFocused] = useState(false)

    const handleChange = useCallback(
      (value: Option) => {
        onChange && onChange(value)
      },
      [onChange],
    )
    const handleMenuOpen = useCallback(() => {
      setIsFocused(true)
    }, [])

    const handleMenuClose = useCallback(() => {
      setIsFocused(false)
    }, [])

    return (
      <Fragment>
        {isFocused && <Overlay />}
        <StyledSelect
          components={{
            IndicatorSeparator: CustomIndicatorSeparator,
            DropdownIndicator: CustomDropdownIndicator,
          }}
          id={name}
          onChange={handleChange}
          options={options.map(intlOption)}
          placeholder={
            placeholder || formatMessage({ id: "form.select.placeholder" })
          }
          isClearable={isClearable}
          className="react-select-container"
          classNamePrefix="react-select"
          isFocused={isFocused}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          value={
            selectedOption
              ? intlOption(selectedOption)
              : noDefault
                ? undefined
                : intlOption(options[0])
          }
          instanceId={instanceId}
          isDisabled={isDisabled}
        />

        {error ? <InputError>{error}</InputError> : null}
      </Fragment>
    )
  },
)

// The same component, but connected to the ReactHookForm context
export const WrappedDropdownSelect = ({
  name,
  options,
  placeholder,
  ...props
}: WrappedProps) => {
  const { watch, setValue } = useFormContext()
  const { field, formState, fieldState } = useController({
    name: name,
    shouldUnregister: true,
  })

  const selectedOption = watch(name)
  const error =
    ((formState.isSubmitted || fieldState.isTouched || fieldState.invalid) &&
      fieldState.error?.message) ||
    ""

  function handleChange(value: Option) {
    if (props.onChange) {
      props.onChange(value)
    }

    setValue(name, value, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <DropdownSelect
      key={`select-${name}-${selectedOption}`} // force re-render on option change
      {...props}
      {...field}
      name={name}
      instanceId={name}
      placeholder={placeholder}
      options={options}
      selectedOption={selectedOption}
      onChange={handleChange}
      error={error}
    />
  )
}

DropdownSelect.displayName = "DropdownSelect"
