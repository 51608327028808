import React from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

import { PdfColoredDarkIcon } from "../../../assets-icons"
import {
  EditableRuleAttachmentWrapper,
  FormIconWrapper,
  InputOptions,
  InputWrapper,
} from "../../../edit-forms"
import {
  FormInput,
  FormItemInput,
  RemoveWithConfirmationButton,
  UploadInput,
} from "../../../forms"
import { P } from "../../../typography"

interface FieldArrayItemProps {
  path: string
  remove: Function
  removeFromIndex: number
}

export const ReportFormItem = ({
  path,
  remove,
  removeFromIndex,
}: FieldArrayItemProps) => {
  const { formatMessage } = useIntl()
  const { watch } = useFormContext()
  const id = watch("id")

  function handleRemoveClick() {
    remove(removeFromIndex)
  }

  return (
    <FormItemInput>
      <EditableRuleAttachmentWrapper data-cy="sport-product-form-report">
        <FormIconWrapper>
          <PdfColoredDarkIcon />
        </FormIconWrapper>
        <InputWrapper>
          <FormInput
            name={`${path}.title`}
            placeholder={formatMessage({ id: `form.report.text.placeholder` })}
            displayAs={P}
            italic
            trimLineBreaks
            data-testid="sport-product-form-report-title"
          />
          <UploadInput
            name={`${path}.url`}
            placeholder={formatMessage({
              id: `form.report.attachment.placeholder`,
            })}
            endpoint="/upload/sport-products"
            extraPostData={{ id }}
            buttonLabel="Upload rapport"
            acceptTypes="application/pdf"
            onDarkBackground
          />
        </InputWrapper>
        <InputOptions>
          <RemoveWithConfirmationButton onConfirm={handleRemoveClick} />
        </InputOptions>
      </EditableRuleAttachmentWrapper>
    </FormItemInput>
  )
}
