import { useSession } from "next-auth/react"
import Link from "next/link"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"

import { Button } from "../button"
import { FilterReset, FilterResetProps } from "../filter-reset"
import { HorizontalFlexSpaced } from "../flex"
import { Message } from "../message"
import { H5 } from "../typography"

interface Props {
  totalResults: number
  hasActiveFilters?: boolean
  onResetClick?: FilterResetProps["onChange"]
  addNew?: {
    href: string
    labelId: string
  }
  inverseVariant?: boolean
}

const StyledH5 = styled(H5, {
  color: "$body",
  variants: {
    white: {
      true: {
        color: "$white",
      },
    },
  },
})

export const ResultsHeader = ({
  totalResults,
  hasActiveFilters,
  onResetClick,
  addNew,
  inverseVariant,
}: Props) => {
  const { data: session } = useSession()
  const { formatMessage } = useIntl()

  return (
    <HorizontalFlexSpaced>
      <FilterResultsContainer>
        <HorizontalFlexSpaced>
          <StyledH5 as="p" data-testid="results" white={inverseVariant}>
            {totalResults} {formatMessage({ id: "results" })}
          </StyledH5>

          {onResetClick && hasActiveFilters && (
            <FilterReset
              onChange={onResetClick}
              alignBottom
              inverseVariant={inverseVariant}
            />
          )}
        </HorizontalFlexSpaced>
      </FilterResultsContainer>

      {session && addNew && (
        <Link href={addNew.href} legacyBehavior>
          <Button
            data-cy="add-new-button"
            icon="plusThin"
            variant="secondary"
            size="large"
            outline
          >
            <Message id={addNew.labelId} />
          </Button>
        </Link>
      )}
    </HorizontalFlexSpaced>
  )
}

const FilterResultsContainer = styled("div", {
  display: "flex",

  "& > *:not(:last-child)": {
    marginRight: "1rem",
  },
})
