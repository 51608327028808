import React, { forwardRef } from "react"
import { styled } from "src/stitches.config"

import { Button } from "../../button"
import { IconWrapper } from "../../icon-wrapper"
import { Message } from "../../message"
import { AccountNavButtonWithButtons } from "./account-nav-button"
import { NavItem } from "./nav-item"
import { NavSubmenuItemLink } from "./nav-submenu-item-link"
import { MobileNavigationWrap } from "./navigation-wraps"

interface MobileNavigationProps {
  onClick?: React.MouseEventHandler<HTMLSpanElement>
  onLogoutClick: (event: any) => void
  onLoginClick: (event: any) => void
}

export const MobileNavigation = ({
  onClick,
  onLogoutClick,
  onLoginClick,
}: MobileNavigationProps) => {
  return (
    <MobileNavigationWrap>
      <NavItem data-cy="hamburger">
        <span onClick={onClick}>
          <IconWrapper name="burger" />
        </span>
      </NavItem>
      <AccountNavButtonWithButtons
        onLogoutClick={onLogoutClick}
        onLoginClick={onLoginClick}
      />
    </MobileNavigationWrap>
  )
}

interface MobileSlideNavigationProps {
  open?: boolean
  onCloseClick?: React.MouseEventHandler<HTMLDivElement>
  items: {
    href: string
    label?: string
    mobileLabel?: string
    items?: {
      href: string
      label?: string
      mobileLabel?: string
    }[]
  }[]
}

const MobileSlideNavigationBase = styled("div", {
  position: "fixed",
  width: "100vw",
  height: "calc(100% - 80px)",
  bottom: "100vh",
  left: "0",
  padding: 5,
  opacity: 0,
  transition: "all .3s",
  zIndex: 1000,
  backgroundColor: "$white",
  "-webkit-overflow-scrolling": "touch",

  variants: {
    open: {
      true: {
        bottom: 0,
        opacity: 1,
      },
    },
  },
})

const MobileSlideNavigationContent = styled("div", {
  height: "100%",
  padding: "5 0",
})

const MobileSlideNavigationParent = styled("span", {
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "$grotesk",

  height: 25,
  margin: "$10",
  clear: "both",
  color: "$orange",
  textAlign: "inherit",
  whiteSpace: "nowrap",
  backgroundColor: "transparent",
  border: 0,

  textDecoration: "none",
})

const CloseWrapper = styled("div", {
  minHeight: "$50",
  padding: "1rem",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: 2,
  color: "$orange",
  cursor: "pointer",

  "& > span": {
    marginRight: "$10",
  },
})

export const MobileSlideNavigation = forwardRef(
  ({ open, onCloseClick, items }: MobileSlideNavigationProps, ref: any) => {
    return (
      <MobileSlideNavigationBase
        open={open}
        data-cy="mobile-navigation"
        ref={ref}
      >
        <MobileSlideNavigationContent onClick={onCloseClick}>
          <CloseWrapper onClick={onCloseClick} data-cy="close-menu">
            <span>
              <Message id="navigation.button.close" />
            </span>
            <Button
              icon="delete"
              variant="lightInverse"
              aria-label="close menu"
            />
          </CloseWrapper>
          {items.map(({ href, label, mobileLabel, items = [] }) => {
            if (items.length) {
              return (
                <div key={href}>
                  <MobileSlideNavigationParent>
                    {label}
                  </MobileSlideNavigationParent>
                  <div style={{ marginLeft: "2rem" }}>
                    {items.map(({ href, label, mobileLabel }) => (
                      <NavSubmenuItemLink
                        key={href}
                        href={href}
                        label={label}
                        data-testid="navigation-subitem"
                      >
                        {mobileLabel || label}
                      </NavSubmenuItemLink>
                    ))}
                  </div>
                </div>
              )
            } else {
              return (
                <NavSubmenuItemLink
                  key={href}
                  href={href}
                  label={label}
                  data-testid="navigation-item"
                >
                  {mobileLabel || label}
                </NavSubmenuItemLink>
              )
            }
          })}
        </MobileSlideNavigationContent>
      </MobileSlideNavigationBase>
    )
  },
)

MobileSlideNavigation.displayName = "MobileSlideNavigation"
