import React, { Fragment, useCallback } from "react"

import { Button } from "../../button"
import { Message } from "../../message"
import { PopOver } from "../../pop-over"

interface Props {
  onConfirm: () => void
}

export const RemoveWithConfirmationButton = (props: Props) => {
  const handleClick = useCallback(() => {
    props.onConfirm()
  }, [props.onConfirm])

  return (
    <PopOver
      trigger={
        <Button
          variant="primary"
          icon="trash"
          as="div"
          size="small"
          data-cy="remove"
          aria-label="verwijder"
        />
      }
      close={
        <Fragment>
          <Button
            variant="primary"
            icon="trash"
            size="regular"
            onClick={handleClick}
            as="div"
            data-testid="confirm"
          >
            <Message id="admin.edit.removeButton" />
          </Button>
          <Button
            variant="lightInverse"
            icon="delete"
            size="regular"
            as="div"
            aria-label="cancel"
            data-cy="cancel"
          />
        </Fragment>
      }
    >
      <p>
        <Message id="admin.edit.remove.confirmation" />
      </p>
    </PopOver>
  )
}
